import React from 'react'
import privacyFile from '~assets/files/politicas-de-privacidad.pdf'
import termsFile from '~assets/files/terminos-y-condiciones.pdf'

import { File } from './Terms.style'

export const Terms = () => (
  <File>
    <object
      id='pdf_content'
      width='100%'
      height='100%'
      type='application/pdf'
      trusted='yes'
      application='yes'
      title='Assembly'
      data={`${termsFile}?#zoom=85&scrollbar=1&toolbar=1&navpanes=1`}
    />
  </File>
)

export const Policies = () => (
  <File>
    <object
      id='pdf_content'
      width='100%'
      height='100%'
      type='application/pdf'
      trusted='yes'
      application='yes'
      title='Assembly'
      data={`${privacyFile}?#zoom=85&scrollbar=1&toolbar=1&navpanes=1`}
    />
  </File>
)
