import React, { useState, useMemo, useContext } from 'react'

const ModalContext = React.createContext()

export function ModalProvider (props) {
  const [showModal, setShowModal] = useState(false)

  function handleOpen () {
    setShowModal(true)
  }

  function handleClose () {
    setShowModal(false)
  }

  const value = useMemo(() => {
    return ({
      showModal,
      handleClose,
      handleOpen
    })
  }, [showModal])

  return <ModalContext.Provider value={value} {...props} />
}

export function useModal () {
  const context = useContext(ModalContext)

  if (!context) {
    throw new Error('useModal should be in ModalContext')
  }

  return context
}
