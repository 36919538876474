import React, { Suspense } from 'react'
import { Router } from '@reach/router'
import { Terms, Policies } from '~pages/Terms'
import { GlobalStyle } from './styles/GlobalStyles'
import { ModalProvider } from './Context/modal-context'

const Home = React.lazy(() => import('./pages/Home'))

function App () {
  return (
    <Suspense fallback={<div />}>
      <GlobalStyle />
      <ModalProvider>
        <Router>
          <Home path='/' />
          <Terms path='terminos-y-condiciones' />
          <Policies path='politicas-de-privacidad' />
        </Router>
      </ModalProvider>
    </Suspense>
  )
}

export default App
