import { createGlobalStyle } from 'styled-components'
import SofiaProLight from '../assets/fonts/SofiaProLight.otf'
import SofiaProRegular from '../assets/fonts/SofiaProRegular.otf'
import SofiaProMedium from '../assets/fonts/SofiaProMedium.otf'
import SofiaProBold from '../assets/fonts/SofiaProBold.otf'

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'sofia-pro light';
    src: url(${SofiaProLight});
    font-style: normal;
  }

  @font-face {
    font-family: 'sofia-pro';
    src: url(${SofiaProRegular});
    font-style: normal;
  }
  
  @font-face {
    font-family: 'sofia-pro medium';
    src: url(${SofiaProMedium});
    font-style: normal;
  }

  @font-face {
    font-family: 'sofia-pro bold';
    src: url(${SofiaProBold});
    font-style: normal;
  }

  html {
    box-sizing: border-box;
    font-family: 'sofia-pro', sans-serif;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  ul, li, h1, h2, h3, h4, p, button {
    margin: 0;
    padding: 0;
  }

  ul {
    list-style: none;
  }

  button: {
    background: transparent;
    border: 0;
    outline: 0;
  }

  body {
    background: #fefefe;
    height: 100vh;
    margin: 0 auto;
    overscroll-behavior: none;
    width: 100%;
  }

  #root {
    min-height: 100vh;
  }

  :root {
    --purple-light: #5d3bab;
    --purple: #604e9a;
    --purple-dark: #26163e;
    --purple-hover: #543475;
    --purple-title: #48358e;
    --gray: #f6f6f6;
    --gray-light: #D8D8D8;
    --white: #FFFFFF;
    --white-alt: #F2F2F2;
    --white-hover: #f6f9fe;
    --red: #F63300;
    --black: #4a4a4a;
    --black-16: rgba(0, 0, 0, 0.16);
    --green: #82AB00;
    --green-dark: #81ad00;

    --font: 'sofia-pro', sans-serif;
    --font-light: 'sofia-pro light', sans-serif;
    --font-medium: 'sofia-pro medium', sans-serif;
    --font-bold: 'sofia-pro bold', sans-serif;
    --font-alt: Helvetica, sans-serif;

    --shadow: 3px 3px 8px 0 rgba(125, 98, 221, 0.28);
  }
`
